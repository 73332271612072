import React from 'react';

export default class CookieAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showLink: window.location.pathname !== '/cookies_policy',
      style: {
        opacity: 0,
        maxHeight: 0,
        transition: 'all 2s ease',
      },
    };
  }

  componentDidMount() {
    setTimeout(this.mountStyle, 500);
  }

  buttonClick = () => {
    document.cookie = 'accepted_cookies=true; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  };

  mountStyle = () => {
    this.setState({
      style: {
        opacity: 1,
        maxHeight: '500px',
        transition: 'all 2s ease',
      },
    });
  };

  render() {
    return this.state.show && (
      <div
        id="cookie-alert"
        className="alert alert-info alert-dismissible text-center p-0 mb-0"
        role="alert"
        style={this.state.style}
      >
        <div className="container mx-auto">
          <div className="row margin-top margin-bottom">
            <div className="col-sm-7 col-center mb-5">
              <p className="my-5">
                <strong>Navigate Wellbeing Solutions uses cookies on this platform. </strong>
                By using the Service, you consent to the use of cookies.

                {this.state.showLink && (
                  <span className="ml-1 ms-1">
                    You can read more about our cookie policy <a href="/cookies_policy">here</a>.
                  </span>
                )}
              </p>
              <button
                type="button"
                className="btn btn-primary btn-block"
                data-bs-dismiss="alert"
                onClick={this.buttonClick}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
